import clsx from "clsx";
import { type FunctionalComponent, type ComponentChildren, h, type RefObject, createRef, JSX } from "preact";
import { useEffect } from "preact/hooks";

type IceButtonWithLoadingProps = JSX.HTMLAttributes<HTMLButtonElement> & {
  children: ComponentChildren;
  btnRef?: RefObject<HTMLButtonElement>;
  initFocus?: boolean;
  focusTimeout?: number;
  btnState: "initial" | "loading" | "invalid" | "disabled" | "error";
};

export const IceButtonWithLoading: FunctionalComponent<IceButtonWithLoadingProps> = ({
  children,
  btnRef,
  initFocus = false,
  btnState = "initial",
  focusTimeout = 0,
  ...delegated
}: IceButtonWithLoadingProps) => {
  const internalRef = btnRef || createRef<HTMLButtonElement>();
  const { className = "" } = delegated;

  useEffect(() => {
    if (!internalRef?.current || !initFocus) return;

    setTimeout(() => {
      internalRef.current?.focus();
    }, focusTimeout);
  }, [internalRef, initFocus, focusTimeout]);

  return (
    <button
      {...delegated}
      className={clsx("btn", className, { "btn--loading disabled": btnState === "loading" })}
      disabled={btnState === "disabled" || btnState === "loading"}
      ref={internalRef}
    >
      {children}

      <div className="btn__ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </button>
  );
};
