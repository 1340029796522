import Cookie, { type CookieAttributes } from "js-cookie";

export const COOKIE_FEEDBACK_KEY = "iceno_feedback";
export const COOKIE_FEEDBACK_PUBLICID_KEY = "iceno_feedback_publicid";
export const COOKIE_FEEDBACK_EXPIRATION_DAYS = 30;

enum FEEDBACK_ORDER_STEP {
  personlia = "personlia",
  abonnement = "abonnement",
  bekreft = "bekreft",
  kvittering = "kvittering",
}

interface abortedOrderState {
  orderStepState?: FEEDBACK_ORDER_STEP;
  feedbackSubmitted?: boolean;
}

type FeedbackCookie = {
  createdAt: number;
  abortedOrderState?: abortedOrderState;
};

export function setFeedbackCookieSubmitted(state: boolean) {
  if (!window.Cookiebot?.consent?.statistics) return;

  const cookieDomain = window.location.hostname.endsWith("ice.no") ? ".ice.no" : ".ice.local";
  const cookieOptions: CookieAttributes = {
    expires: COOKIE_FEEDBACK_EXPIRATION_DAYS,
    domain: cookieDomain,
  };

  if (window.location.protocol === "https:") {
    cookieOptions.secure = true;
    cookieOptions.sameSite = "None";
  }

  const stringifiedExistingCookie = Cookie.get(COOKIE_FEEDBACK_KEY);
  const newCookieValue = JSON.parse(stringifiedExistingCookie ?? "{}") as FeedbackCookie;
  // clear existing cookie
  if (Cookie.get(COOKIE_FEEDBACK_KEY)) Cookie.remove(COOKIE_FEEDBACK_KEY);
  newCookieValue.abortedOrderState = { feedbackSubmitted: state };

  Cookie.set(COOKIE_FEEDBACK_KEY, JSON.stringify(newCookieValue), cookieOptions);
}

export const shouldFormShowBasedOnFeedbackCookie = () => {
  const feedbackCookieStates = getStateFromFeedbackCookie();
  if (feedbackCookieStates === undefined) return false;
  // User has already submitted feedback
  if (feedbackCookieStates.feedbackSubmitted) return false;
  // User has completed the entire order forms process
  if (feedbackCookieStates.orderStepState === FEEDBACK_ORDER_STEP.kvittering) return false;

  return true;
};

export function getStateFromFeedbackCookie() {
  const existingCookie = Cookie.get(COOKIE_FEEDBACK_KEY);
  if (existingCookie === undefined) return undefined;
  const cookieValue = JSON.parse(existingCookie) as FeedbackCookie;

  return cookieValue.abortedOrderState;
}
