import { type FunctionalComponent, h } from "preact";
import { useState, useEffect, useRef, useId } from "preact/hooks";
import { ICE_FEEDBACK_EVENT_KEY, type IFeedbackEvent, IceFeedbackFormSteps } from "../Component/ice-feedback-component";
import { shouldFormShowBasedOnFeedbackCookie, getStateFromFeedbackCookie } from "../ice-feedback-cookie-utilities";
import { IceFeedbackPopupContent } from "./components/content";
import { IceFeedbackPopupHeader } from "./components/header";

export const IceFeedbackPopup: FunctionalComponent<{ show: boolean }> = ({ show }: { show: boolean }) => {
  const [enabledInCMS, setEnabledInCMS] = useState<boolean>(show);
  const [showComponent, setShowComponent] = useState<boolean>(shouldFormShowBasedOnFeedbackCookie() && enabledInCMS);
  const [userFeedbackPositive, setUserFeedbackPositive] = useState<boolean | undefined>(undefined);

  const headerAccessibilityId = useId();
  const feedbackDialogRef = useRef<HTMLDivElement>(null);

  const [openButton, setOpenButton] = useState<HTMLElement>();

  const closeHandler = () => {
    if (openButton) openButton.focus();
  };

  useEffect(() => {
    const handleFeedbackEvent = (e: IFeedbackEvent) => {
      const feedbackCookie = getStateFromFeedbackCookie();
      if (!feedbackCookie?.feedbackSubmitted) {
        setShowComponent(e.data.showFeedback);
        setEnabledInCMS(e.data.showFeedback);
        setOpenButton(e.data.openButton);
      }
    };

    document.addEventListener(ICE_FEEDBACK_EVENT_KEY, handleFeedbackEvent);
    return () => {
      document.removeEventListener(ICE_FEEDBACK_EVENT_KEY, handleFeedbackEvent);
    };
  }, []);

  const [feedbackFormStep, setFeedbackFormStep] = useState<IceFeedbackFormSteps>(IceFeedbackFormSteps.prompt);

  if (!showComponent) return;

  return (
    <div className={"ice-feedback ice-feedback--popup"} aria-atomic="true" role="dialog" ref={feedbackDialogRef}>
      <IceFeedbackPopupHeader
        feedbackFormStep={feedbackFormStep}
        setShowComponent={setShowComponent}
        setFeedbackFormStep={setFeedbackFormStep}
        userFeedbackPositive={userFeedbackPositive}
        feedbackDialogRef={feedbackDialogRef}
        headerAccessibilityId={headerAccessibilityId}
        closeHandler={closeHandler}
      />
      <IceFeedbackPopupContent
        feedbackFormStep={feedbackFormStep}
        setFeedbackFormStep={setFeedbackFormStep}
        setUserFeedbackPositive={setUserFeedbackPositive}
        userFeedbackPositive={userFeedbackPositive}
        headerAccessibilityId={headerAccessibilityId}
      />
    </div>
  );
};
