import type { RefObject } from "preact";

const focusTrapHandler = (e: KeyboardEvent, container: HTMLElement) => {
  e.preventDefault();
  e.stopPropagation();

  const inputs = [].slice.call(
    container.querySelectorAll<Element>("button, input, a[tabindex='0'], textarea"),
  ) as Element[];
  const index = inputs.indexOf(document.activeElement as Element);

  let el: Element | undefined;
  if (e.shiftKey) {
    el = index > 0 ? inputs[index - 1] : inputs[inputs.length - 1];
  } else {
    el = index + 1 < inputs.length ? inputs[index + 1] : inputs[0];
  }
  (el as HTMLElement).focus();
};

export const keyDownHandler = (containerRef: RefObject<HTMLElement>, closeModal: (e: Event) => void) => {
  return (e: KeyboardEvent): void => {
    if (e.key === "Tab") {
      focusTrapHandler(e, containerRef.current as HTMLElement);
    }
    if (e.key === "Escape") {
      closeModal(e);
    }
  };
};
