import { createRef, type FunctionComponent, h, type RefObject } from "preact";
import { IceFeedbackFormSteps } from "../../Component/ice-feedback-component";
import { setFeedbackCookieSubmitted } from "../../ice-feedback-cookie-utilities";
import { IceSvgByName } from "../../Component/components/form";
import { useEffect } from "preact/hooks";
import { sendFeedbackAnalyticsEventForPopup } from "@shared/Analytics/content/feedback-analytics";
import { keyDownHandler } from "@shared/Utils/keydown-helpers";

interface IceFeedbackHeaderPopupProps {
  feedbackFormStep: IceFeedbackFormSteps;
  setShowComponent: (showComponent: boolean) => void;
  setFeedbackFormStep: (step: IceFeedbackFormSteps) => void;
  userFeedbackPositive?: boolean;
  feedbackDialogRef: RefObject<HTMLDivElement>;
  headerAccessibilityId: string;
  closeHandler: () => void;
}

export const IceFeedbackPopupHeader: FunctionComponent<IceFeedbackHeaderPopupProps> = ({
  feedbackFormStep,
  setShowComponent,
  setFeedbackFormStep,
  userFeedbackPositive,
  feedbackDialogRef,
  headerAccessibilityId,
  closeHandler,
}: IceFeedbackHeaderPopupProps) => {
  const closeButtonRef = createRef<HTMLButtonElement>();
  const totalFormQuestions = Object.keys(IceFeedbackFormSteps).length / 2 - 1;
  const handleCloseFeedbackClick = () => {
    setShowComponent(false);
    setFeedbackCookieSubmitted(true); // feedback popup should not be visible again after closing it
    if (feedbackFormStep === IceFeedbackFormSteps.details) {
      sendFeedbackAnalyticsEventForPopup(
        userFeedbackPositive ? "ja_window_closed" : "nei_window_closed",
        "feedback_question_2",
      );
    }

    setFeedbackFormStep(IceFeedbackFormSteps.prompt);
    closeHandler();
  };

  const stepProgressText =
    feedbackFormStep !== IceFeedbackFormSteps.success
      ? `Spørsmål ${feedbackFormStep} av ${totalFormQuestions}`
      : "Vår favorittkunde har talt";

  useEffect(() => {
    if (!closeButtonRef?.current || feedbackFormStep !== IceFeedbackFormSteps.success) return;
    closeButtonRef.current.focus();
  }, [closeButtonRef, feedbackFormStep]);

  useEffect(() => {
    if (!feedbackDialogRef?.current) return;
    feedbackDialogRef.current.addEventListener("keydown", keyDownHandler(feedbackDialogRef, handleCloseFeedbackClick));
    return () => {
      feedbackDialogRef?.current?.removeEventListener(
        "keydown",
        keyDownHandler(feedbackDialogRef, handleCloseFeedbackClick),
      );
    };
  }, [feedbackDialogRef]);

  return (
    <div className="ice-feedback__header">
      <div role="status" id={headerAccessibilityId}>
        {stepProgressText}
      </div>
      <button
        type="button"
        className="btn--feedback__popup btn--feedback__close"
        onClick={() => handleCloseFeedbackClick()}
        aria-label="Lukk tilbakemeldingsboks"
        ref={closeButtonRef}
      >
        <IceSvgByName name={"ice-close"} />
      </button>
    </div>
  );
};
